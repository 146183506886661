import '@utils/sentrySetup';
import { CssBaseline } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';

import { Auth } from '@components/Auth';
import { snackbarVariants } from '@components/Snackbar/config';
import { defaultQueryFn } from '@utils/apiClient';

import { AppRoutes } from './AppRoutes';
import { ThemeProvider } from './ThemeProvider';

import './App.css';
import '../../i18n.config';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // invalidate manually after mutation calls
      staleTime: Infinity,
      queryFn: defaultQueryFn,
    },
  },
});

export const App = () => {
  return (
    <ThemeProvider>
      <CssBaseline />
      <SnackbarProvider Components={snackbarVariants} maxSnack={3}>
        <Auth>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
          </QueryClientProvider>
        </Auth>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

import LoadingButton from '@mui/lab/LoadingButton';
import { Autocomplete, FormControl, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { useGeoJsonFeatures } from '@hooks/useGeoJsonFeatures';

interface AutocompleteOption {
  label: string;
  id: string;
}

export const ProjectAssignment = ({
  onAssign,
  isOpen,
  loading,
  isSuccess,
  hasProject,
}: {
  onAssign: (projectId: string, projectName: string) => void;
  isOpen: boolean;
  loading: boolean;
  isSuccess: boolean;
  hasProject: boolean;
}) => {
  const { projectsSource } = useGeoJsonFeatures();
  const [displayedInput, setDisplayedInput] = useState<AutocompleteOption | undefined>(undefined);
  const [selectedProject, setSelectedProject] = useState<AutocompleteOption | null>(
    displayedInput || null,
  );
  const projectOptions = projectsSource.features.map<AutocompleteOption>(({ properties }) => ({
    label: properties?.name,
    id: properties?.id,
  }));

  const assignProject = () =>
    selectedProject && onAssign(selectedProject.id, selectedProject.label);

  useEffect(() => {
    isOpen && setSelectedProject(null);
  }, [isOpen]);

  useEffect(() => {
    isSuccess && setSelectedProject(null);
  }, [isSuccess]);

  return (
    <>
      <Typography component="div" gutterBottom variant="h6">
        {hasProject ? 'Edit' : 'Search'} project
      </Typography>
      <Grid
        alignItems={'center'}
        container
        direction={'row'}
        gap={2}
        paddingBottom={2}
        wrap="nowrap"
      >
        <Grid item xs={8}>
          <FormControl fullWidth>
            <Autocomplete
              data-test-id="project-assignment-list"
              disabled={loading}
              id="project-assignment"
              inputValue={displayedInput?.label || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, project) => {
                project && setSelectedProject(project);
              }}
              onInputChange={(_, projectName) => {
                setDisplayedInput({ label: projectName, id: '' });
              }}
              options={projectOptions}
              renderInput={(params) => <TextField {...params} label="Project" size="medium" />}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.label}
                </li>
              )}
              value={selectedProject}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <LoadingButton
            data-test-id="assign-button"
            disabled={!selectedProject?.id}
            fullWidth
            loading={loading}
            onClick={assignProject}
            size={'large'}
            variant="outlined"
          >
            Assign
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

import type { CircleLayer, CirclePaint } from 'mapbox-gl';
import { useContext } from 'react';
import { Layer } from 'react-map-gl';

import { useFilters } from '@components/FilterBox/useFilters';
import { MIN_ZOOM, SourceLayerId } from '@components/MapSource/constants';
import type { Asset } from '@models/asset';
import {
  filterPointAllExpression,
  filterPointSelectedExpression,
  filterPointUnassignedAllExpression,
} from '@utils/mapBoxExpressions';

import { ProjectColorsContext } from './MapSource';

type PointsLayerProps = {
  source: string;
  sourceLayer: SourceLayerId;
  layerId: string;
};

const defaultPointPaintStyle: CirclePaint = {
  'circle-radius': [
    'interpolate',
    ['linear'],
    ['zoom'],
    6,
    7,
    // zoom is 9 (or less) -> circle radius will be 5px
    9,
    8,
    // zoom is 10 (or greater) -> circle radius will be 6px
    10,
    9,
  ],
  'circle-color-transition': { duration: 500 },
  'circle-pitch-scale': 'map',
};

export const PointsFilteredLayer = ({ source, sourceLayer, layerId }: PointsLayerProps) => {
  const colors = useContext(ProjectColorsContext);
  const filters = useFilters();
  const pointsFilter = filterPointAllExpression(filters, sourceLayer);

  const layerStyle: CircleLayer = {
    id: layerId,
    type: 'circle',
    paint: {
      ...defaultPointPaintStyle,
      'circle-color': ['match', ['get', 'project_id'], ...colors],
    },
    filter: pointsFilter,
    minzoom: MIN_ZOOM,
    source,
    'source-layer': sourceLayer,
  };

  return <Layer {...layerStyle} />;
};

export const PointsUnassignedLayer = ({ source, sourceLayer, layerId }: PointsLayerProps) => {
  const colors = useContext(ProjectColorsContext);
  const filters = useFilters();
  const pointsFilter = filterPointUnassignedAllExpression(filters, sourceLayer);

  const layerStyle: CircleLayer = {
    id: layerId,
    type: 'circle',
    paint: {
      ...defaultPointPaintStyle,
      'circle-color': ['match', ['get', 'project_id'], ...colors],
    },
    filter: pointsFilter,
    source,
    'source-layer': sourceLayer,
  };

  return <Layer {...layerStyle} />;
};

export const PointsSelectedLayer = ({
  selectedFeatures,
  source,
  sourceLayer,
  layerId,
}: PointsLayerProps & {
  selectedFeatures: Asset[];
}) => {
  const pointsFilters = filterPointSelectedExpression(selectedFeatures);
  const colors = useContext(ProjectColorsContext);

  const layerStyle: CircleLayer = {
    id: layerId,
    type: 'circle',
    paint: {
      ...defaultPointPaintStyle,
      'circle-stroke-color': '#ffffff',
      'circle-color': ['match', ['get', 'project_id'], ...colors],
      'circle-stroke-width': 4,
    },
    filter: pointsFilters,
    source,
    'source-layer': sourceLayer,
  };

  return <Layer {...layerStyle} />;
};

import {
  green,
  indigo,
  blue,
  cyan,
  teal,
  lime,
  yellow,
  amber,
  lightBlue,
  lightGreen,
  brown,
  grey,
  red,
} from '@mui/material/colors';
import type { FeatureCollection } from 'geojson';
import type { DateTime } from 'luxon';
import type { Expression } from 'mapbox-gl';

import type { Filters } from '@components/FilterBox/useFilters';
import { SourceLayerId } from '@components/MapSource/constants';
import type { Asset } from '@models/asset';

const colorsLight = [
  green[500],
  indigo[500],
  blue[500],
  cyan[500],
  teal[500],
  lime[500],
  yellow[500],
  amber[500],
  lightBlue[500],
  lightGreen[500],
  grey[500],
  brown[500],
  green[800],
  indigo[800],
  blue[800],
  cyan[800],
  teal[800],
  lime[800],
  yellow[800],
  amber[800],
  lightBlue[800],
  lightGreen[800],
  grey[800],
  brown[800],
];

const colorsDark = [
  green[300],
  indigo[300],
  blue[300],
  cyan[300],
  teal[300],
  lime[300],
  yellow[300],
  amber[300],
  lightBlue[300],
  lightGreen[300],
  grey[300],
  brown[300],
  green[100],
  indigo[100],
  blue[100],
  cyan[100],
  teal[100],
  lime[100],
  yellow[100],
  amber[100],
  lightBlue[100],
  lightGreen[100],
  grey[100],
  brown[100],
];

const fallbackColorLight = red[500];
const fallbackColorDark = red[300];

export const projectCloseupThreshold = 12;

export const filterPointAssignedExpression = () => [['!=', ['get', 'project_id'], null]];

export const filterPointUnassignedExpression = () => [['==', ['get', 'project_id'], null]];

const dateExpressions: Record<SourceLayerId, Expression> = {
  [SourceLayerId.SCAN]: ['slice', ['get', 'begin_date'], 0, 10],
  [SourceLayerId.PHOTO]: ['slice', ['get', 'date'], 0, 10],
};

const filterProjectCustomersExpression = (customers: string[]) => {
  return customers.length && customers[0] !== 'all'
    ? [['in', ['get', 'org_name'], ['literal', customers]]]
    : [];
};

export const filterProjectAllExpression = ({ customers }: Filters) => {
  return ['all', ...filterProjectCustomersExpression(customers)] as Expression;
};

const filterDateRangeExpression = (
  sourceLayerId: SourceLayerId,
  from: DateTime | null,
  to: DateTime | null,
) => {
  const sourceDate = dateExpressions[sourceLayerId];

  if (!sourceDate) return [];

  return [
    ...(to?.isValid ? [['<=', sourceDate, to.toISODate()]] : []),
    ...(from?.isValid ? [['>=', sourceDate, from.toISODate()]] : []),
  ];
};

const filterScanDevicesExpression = (scanDevices: string[]) => {
  return scanDevices.length && scanDevices[0] !== 'all'
    ? [['in', ['get', 'scan_device_name'], ['literal', scanDevices]]]
    : [];
};

const filterCustomersExpression = (customers: string[], sourceLayer: SourceLayerId) => {
  return customers.length && customers[0] !== 'all'
    ? [
        [
          'in',
          [
            'get',
            sourceLayer === SourceLayerId.SCAN
              ? 'scan_organization_name'
              : 'photo_organization_name',
          ],
          ['literal', customers],
        ],
      ]
    : [];
};

export const filterPointAllExpression = (
  { scanDevices, showAll, from, to, customers }: Filters,
  sourceLayer: SourceLayerId,
) => {
  return [
    'all',
    ...filterScanDevicesExpression(scanDevices),
    ...filterCustomersExpression(customers, sourceLayer),
    ...filterDateRangeExpression(sourceLayer, from, to),
    ...filterPointAssignedExpression(),
    showAll,
  ] as Expression;
};

export const filterDateAllExpression = (
  { showAll, from, to }: Filters,
  sourceLayer: SourceLayerId,
) => {
  return [
    'all',
    ...filterDateRangeExpression(sourceLayer, from, to),
    ...(showAll ? [] : filterPointUnassignedExpression()),
  ] as Expression;
};

export const filterPointUnassignedAllExpression = (
  { scanDevices, from, to }: Filters,
  sourceLayer: SourceLayerId,
) =>
  [
    'all',
    ...filterScanDevicesExpression(scanDevices),
    ...filterDateRangeExpression(sourceLayer, from, to),
    ...filterPointUnassignedExpression(),
  ] as Expression;

export const filterPointSelectedExpression = (selectedFeatures: Asset[]) =>
  ['in', 'id', ...selectedFeatures.map((feature) => feature.id)] as Expression;

export const generateProjectColors = (
  projectsSource: FeatureCollection,
  themeMode: 'light' | 'dark',
) => {
  const colors = themeMode === 'light' ? colorsLight : colorsDark;
  const fallbackColor = themeMode === 'light' ? fallbackColorLight : fallbackColorDark;

  return [
    ...projectsSource.features.reduce((acc, feature, idx) => {
      return [...acc, feature.properties?.id, colors[idx % colors.length]];
    }, [] as string[]),
    fallbackColor,
  ];
};

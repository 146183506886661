import { CircleInformationOutline } from '@deepup/icons';
import { FormControlLabel, Checkbox, Paper, Typography, Stack } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';

import { useFilters } from '@components/FilterBox/useFilters';
import { useSourceOptions } from '@components/FilterBox/useSourceOptions';
import { MIN_ZOOM } from '@components/MapSource/constants';
import { useMapZoom } from '@hooks/useMapZoom';

import { GeocoderField } from './GeocoderField';
import { SelectBoxMulti } from './SelectBoxMulti';

const localeCompare = (a: string, b: string) => a.localeCompare(b);

export const FilterBox = () => {
  const {
    to,
    from,
    showAll,
    setFilters,
    scanDevices: filterDevices,
    customers: filterCustomers,
  } = useFilters();

  const { sourceDevices, sourceCustomers, updateSourceOptions } = useSourceOptions();
  const deviceOptions = uniq([...filterDevices, ...sourceDevices]).sort(localeCompare);
  const customerOptions = uniq([...filterCustomers, ...sourceCustomers]).sort(localeCompare);
  const zoom = useMapZoom();

  return (
    <Paper data-test-id="filter-box" elevation={2}>
      <Stack direction={'column'} padding={2} spacing={2}>
        <GeocoderField />
        <SelectBoxMulti
          availableItems={customerOptions}
          disabled={zoom < MIN_ZOOM || customerOptions.length === 0}
          items={filterCustomers}
          label="Customer"
          onOpen={updateSourceOptions}
          setItems={(selectedItems) => setFilters({ customers: selectedItems })}
        />

        <Stack direction={'row'} gap={2}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              format="dd.MM.yyyy"
              label="From"
              maxDate={DateTime.now()}
              onChange={(date: DateTime | null) => setFilters({ from: date })}
              value={from}
            />
            <DatePicker
              format="dd.MM.yyyy"
              label="To"
              onChange={(date: DateTime | null) => setFilters({ to: date })}
              value={to}
            />
          </LocalizationProvider>
        </Stack>

        <SelectBoxMulti
          availableItems={deviceOptions}
          disabled={zoom < MIN_ZOOM || deviceOptions.length === 0}
          items={filterDevices}
          label="Scan Devices"
          onOpen={updateSourceOptions}
          setItems={(selectedItems) => setFilters({ scanDevices: selectedItems })}
        />

        <FormControlLabel
          checked={showAll}
          control={<Checkbox />}
          defaultChecked={showAll}
          label="Show all assets"
          onChange={(e, checked) => setFilters({ showAll: checked })}
        />
        {zoom < MIN_ZOOM && (
          <Stack alignItems={'center'} direction="row" paddingLeft={1} spacing={1}>
            <CircleInformationOutline fontSize="large" style={{ minWidth: '20px' }} />
            <Typography>
              {showAll ? (
                <>Zoom closer to see assigned assets and filter by Scan Device and Customer.</>
              ) : (
                <>Zoom closer to filter by Scan Device and Customer.</>
              )}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};

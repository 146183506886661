import {
  ThemePaletteModeContext,
  ThemeProvider as DeepUpThemeProvider,
  useDeepUpTheme,
} from '@deepup/mui-theme-deepup';
import { createTheme } from '@mui/material';
import { deDE as coreDeDE } from '@mui/material/locale';
import { deDE } from '@mui/x-date-pickers/locales';
import { useMemo } from 'react';

type Props = {
  children: React.ReactNode;
};

export const ThemeProvider = ({ children }: Props) => {
  const { themePaletteModeContextValue, theme } = useDeepUpTheme();
  const themeWithLocale = useMemo(
    () =>
      createTheme(
        {
          ...theme,
          ...{
            shape: {
              ...theme.shape,
              ...{
                borderRadius: 8,
              },
            },
            components: {
              ...theme.components,
              ...{
                MuiDrawer: {
                  styleOverrides: {
                    paper: {
                      borderRadius: 0,
                    },
                  },
                },
              },
              ...{
                MuiChip: {
                  styleOverrides: {
                    root: {
                      borderRadius: '8px',
                    },
                  },
                },
              },
              ...{
                MuiToggleButton: {
                  styleOverrides: {
                    root: {
                      textTransform: 'none',
                    },
                  },
                },
              },
              ...{
                MuiPopper: {
                  // TODO: why it does not like this?
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  styleOverrides: {
                    root: {
                      '& .MuiTypography-body1': {
                        fontSize: '15px',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        deDE,
        coreDeDE,
      ),
    [theme],
  );

  return (
    <ThemePaletteModeContext.Provider value={themePaletteModeContextValue}>
      <DeepUpThemeProvider theme={themeWithLocale}>{children}</DeepUpThemeProvider>
    </ThemePaletteModeContext.Provider>
  );
};

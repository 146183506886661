import type { Feature } from 'geojson';
import { useState } from 'react';
import { type MapGeoJSONFeature, type MapRef, useMap } from 'react-map-gl';

import {
  MIN_ZOOM,
  SourceId,
  SourceLayerId,
  interactiveLayers,
  LayerId,
} from '@components/MapSource/constants';
import { useMapEvent } from '@hooks/useMapEvent';
import { filterDateAllExpression } from '@utils/mapBoxExpressions';

import { useFilters } from './useFilters';

const getUniqueOptions = (features: MapGeoJSONFeature[] | Feature[]) => {
  const sourceDevices: string[] = [];
  const sourceCustomers: string[] = [];

  for (let i = 0; i < features.length; i++) {
    const deviceName = features[i].properties?.scan_device_name;

    if (deviceName && !sourceDevices.includes(deviceName)) {
      sourceDevices.push(deviceName);
    }
    const customerName =
      features[i].properties?.scan_organization_name ||
      features[i].properties?.photo_organization_name ||
      features[i].properties?.org_name;

    if (customerName && !sourceCustomers.includes(customerName)) {
      sourceCustomers.push(customerName);
    }
  }

  return { sourceDevices, sourceCustomers };
};

const hasLayers = (map: MapRef) => interactiveLayers.every((layerId) => map.getLayer(layerId));

type SourceOptions = Record<'sourceDevices' | 'sourceCustomers', string[]>;
const INITIAL_STATE = {
  sourceDevices: [],
  sourceCustomers: [],
};

export const useSourceOptions = () => {
  const [sourceOptions, setSourceOptions] = useState<SourceOptions>(INITIAL_STATE);
  const { current: map } = useMap();
  const filters = useFilters();

  const updateSourceOptions = () => {
    const isVisible = map && map.getZoom() > MIN_ZOOM && hasLayers(map);

    if (!isVisible) {
      return;
    }

    const features = [
      ...map.querySourceFeatures(SourceId.SCAN, {
        sourceLayer: SourceLayerId.SCAN,
        filter: filterDateAllExpression(filters, SourceLayerId.SCAN),
      }),
      ...map.querySourceFeatures(SourceId.PHOTO, {
        sourceLayer: SourceLayerId.PHOTO,
        filter: filterDateAllExpression(filters, SourceLayerId.PHOTO),
      }),
      ...map.querySourceFeatures(SourceId.PROJECT, {
        sourceLayer: LayerId.PROJECT_LINE,
      }),
    ];

    if (!features.length) {
      setSourceOptions(INITIAL_STATE);

      return;
    }

    const options = getUniqueOptions(features);

    setSourceOptions(options);
  };

  useMapEvent('idle', updateSourceOptions);
  useMapEvent('sourcedata', updateSourceOptions);

  return { ...sourceOptions, updateSourceOptions };
};

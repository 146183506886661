import type { BBox } from 'geojson';
import { LngLatBounds } from 'mapbox-gl';

export const bboxToBounds = (bbox: BBox) => {
  // 3D ?
  if (bbox.length === 6) {
    return new LngLatBounds({ lng: bbox[0], lat: bbox[1] }, { lng: bbox[3], lat: bbox[4] });
  }

  // otherwise 2D
  return new LngLatBounds({ lng: bbox[0], lat: bbox[1] }, { lng: bbox[2], lat: bbox[3] });
};

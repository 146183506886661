import { useQuery } from '@tanstack/react-query';
import type { FeatureCollection } from 'geojson';
import { useSearchParams } from 'react-router-dom';

import type { Asset } from '@models/asset';
import { apiClient } from '@utils/apiClient';

export const useAssetInfos = () => {
  const [searchParams] = useSearchParams();

  const assetString = searchParams.get('asset');
  const splittedAssetString = assetString?.split('.');
  const assetType = splittedAssetString?.at(0)?.toUpperCase();
  const assetId = splittedAssetString?.at(1);

  const { data } = useQuery<FeatureCollection>({
    queryKey: ['initial-selected-asset'],
    queryFn: async () => {
      if (!assetId) {
        return { features: [] };
      }

      const res = await apiClient(`/geoserver/geojson?typeName=${assetType}&id=${assetId}`);

      return res;
    },
  });

  if (!assetId) {
    return { assetInfos: [] };
  }

  const assetFeatures = data?.features.at(0);

  if (!assetFeatures) {
    return { assetInfos: [] };
  }

  const assetFeatureId = assetFeatures.id ? String(assetFeatures.id) : undefined;

  const responseAssetType = assetFeatureId?.split('.').at(0);
  const assetInfos = [{ ...assetFeatures.properties, asset_type: responseAssetType }] as Asset[];

  return { assetInfos };
};

import { type ReactNode } from 'react';
import { Popup as PopupGl, type LngLat } from 'react-map-gl';

import { useThemeMode } from '@hooks/useThemeMode';

export const Popup = ({ children, coords }: { children: ReactNode; coords: LngLat | null }) => {
  const { isDarkMode } = useThemeMode();

  if (!coords) {
    return null;
  }

  return (
    <PopupGl
      anchor="top"
      className={isDarkMode ? 'dark' : ''}
      closeOnClick={false}
      closeOnMove={false}
      latitude={coords.lat}
      longitude={coords.lng}
      offset={16}
      style={{ borderBottomColor: isDarkMode ? 'black' : '#000' }}
    >
      {children}
    </PopupGl>
  );
};

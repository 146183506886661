import {
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  type SelectChangeEvent,
} from '@mui/material';
import isEqual from 'lodash/isEqual';

type SelectBoxMultiProps<T extends string> = {
  label: string;
  availableItems: T[];
  items: T[];
  setItems: (items: string[]) => void;
  helperText?: string;
  disabled?: boolean;
  t?: (label: T) => T;
  onOpen: () => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const SelectBoxMulti = <T extends string>({
  label,
  helperText,
  availableItems,
  items,
  setItems,
  disabled,
  t,
  onOpen,
}: SelectBoxMultiProps<T>) => {
  const isChecked =
    (availableItems.length === 0 && items.length > 0) ||
    (availableItems.length > 0 && isEqual(availableItems, items));
  const isIndeterminate =
    availableItems.length > 0 && items.length > 0 && items.length < availableItems.length;

  const onChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;

    if (value.includes('_ALL_') && (isIndeterminate || !isChecked)) {
      setItems(availableItems);
    } else if (value.includes('_ALL_') && isChecked) {
      setItems([]);
    } else if (typeof value === 'string') {
      // On autofill, we get a stringified value.
      setItems(value.split(',') as T[]);
    } else {
      setItems(value as T[]);
    }
  };

  return (
    <FormControl sx={{ m: 1, width: '100%', margin: 0 }}>
      <InputLabel
        data-testid="filter-select-form-label"
        id={`${label.toLowerCase()}-select-label`}
        shrink
      >
        {label}
      </InputLabel>
      <Select
        MenuProps={{
          autoFocus: false,
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
          },
        }}
        disabled={disabled}
        displayEmpty
        input={<OutlinedInput label={label} />}
        labelId={`${label.toLowerCase()}-select-label`}
        multiple
        notched
        onChange={onChange}
        onOpen={onOpen}
        renderValue={() =>
          !isChecked && !isIndeterminate
            ? 'Nothing selected'
            : isIndeterminate
              ? `${items.length} selected`
              : 'All selected'
        }
        value={items}
      >
        <MenuItem value="_ALL_">
          <Checkbox checked={isChecked} indeterminate={isIndeterminate} />
          <ListItemText primary="All" />
        </MenuItem>
        <Divider variant="middle" />
        {availableItems.map((item) => (
          <MenuItem data-testid={`filter-select-form-menuitem-${item}`} key={item} value={item}>
            <Checkbox checked={items.map((t) => t.toString()).includes(item)} />
            <ListItemText primary={t ? t(item) : item} />
          </MenuItem>
        ))}
      </Select>
      <Collapse in={!!helperText}>
        <FormHelperText>{helperText ?? ' '}</FormHelperText>
      </Collapse>
    </FormControl>
  );
};

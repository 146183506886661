export const getEnvironment = () => {
  return {
    mapboxAccessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
    auth0Audience: import.meta.env.VITE_MY_AUTH0_AUDIENCE,
    auth0ClientId: import.meta.env?.VITE_MY_AUTH0_CLIENT_ID,
    auth0Domain: import.meta.env?.VITE_MY_AUTH0_DOMAIN,
    isIntegrationTest: import.meta.env.MODE === 'integration',
    apiBaseUrl: import.meta.env?.VITE_API_BASE_URL,
    sentryDsn: import.meta.env.VITE_SENTRY_DSN,
    sentryAuthToken: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
    mode: import.meta.env.MODE,
    dev: import.meta.env.DEV,
    appVersion: import.meta.env.VITE_APP_VERSION,
  };
};

import { useDeepUpTheme } from '@deepup/mui-theme-deepup';
import { createContext } from 'react';
import { Source } from 'react-map-gl';

import { useFilters } from '@components/FilterBox/useFilters';
import { LayerId, MIN_ZOOM, SourceId, SourceLayerId } from '@components/MapSource/constants';
import { useGeoJsonFeatures } from '@hooks/useGeoJsonFeatures';
import { useMapZoom } from '@hooks/useMapZoom';
import type { Asset } from '@models/asset';
import { getEnvironment } from '@utils/getEnvironment';
import { generateProjectColors } from '@utils/mapBoxExpressions';

import { PointsFilteredLayer, PointsSelectedLayer, PointsUnassignedLayer } from './PointsLayers';
import { ProjectsFillLayer, ProjectsLineLayer } from './ProjectsLayers';

const { apiBaseUrl } = getEnvironment();

export const ProjectColorsContext = createContext<string[]>([]);

export const MapSource = ({ selectedFeatures }: { selectedFeatures: Asset[] }) => {
  const { theme } = useDeepUpTheme();
  const zoom = useMapZoom();
  const { showAll } = useFilters();
  const sourceFilter = zoom < MIN_ZOOM || !showAll ? '&projectIds=UNASSIGNED' : '';
  const { projectsSource } = useGeoJsonFeatures();

  const generatedColorExpression = generateProjectColors(projectsSource, theme.palette.mode);

  // Wait for generatedColorExpression to be populated before rendering the map features
  // We could add some loading animation if it takes too long on production
  if (generatedColorExpression.length <= 1) return null;

  const scansSource = `${apiBaseUrl}/geoserver/map?bbox={bbox-epsg-3857}&layer=SCAN${sourceFilter}`;
  const photosSource = `${apiBaseUrl}/geoserver/map?bbox={bbox-epsg-3857}&layer=PHOTO${sourceFilter}`;

  return (
    <ProjectColorsContext.Provider value={generatedColorExpression}>
      <Source data={projectsSource} id={SourceId.PROJECT} type="geojson">
        <ProjectsLineLayer />
        <ProjectsFillLayer />
      </Source>

      <Source id={SourceId.PHOTO} tiles={[photosSource]} type="vector" />
      <Source id={SourceId.SCAN} tiles={[scansSource]} type="vector" />

      <PointsFilteredLayer
        layerId={LayerId.PHOTO}
        source={SourceId.PHOTO}
        sourceLayer={SourceLayerId.PHOTO}
      />
      <PointsFilteredLayer
        layerId={LayerId.SCAN}
        source={SourceId.SCAN}
        sourceLayer={SourceLayerId.SCAN}
      />
      <PointsUnassignedLayer
        layerId={LayerId.PHOTO_UNASSIGNED}
        source={SourceId.PHOTO}
        sourceLayer={SourceLayerId.PHOTO}
      />
      <PointsSelectedLayer
        layerId={LayerId.PHOTO_SELECTED}
        selectedFeatures={selectedFeatures}
        source={SourceId.PHOTO}
        sourceLayer={SourceLayerId.PHOTO}
      />

      <PointsUnassignedLayer
        layerId={LayerId.SCAN_UNASSIGNED}
        source={SourceId.SCAN}
        sourceLayer={SourceLayerId.SCAN}
      />
      <PointsSelectedLayer
        layerId={LayerId.SCAN_SELECTED}
        selectedFeatures={selectedFeatures}
        source={SourceId.SCAN}
        sourceLayer={SourceLayerId.SCAN}
      />
    </ProjectColorsContext.Provider>
  );
};

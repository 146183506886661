export const MIN_ZOOM = 10;

export enum SourceId {
  PHOTO = 'photo',
  SCAN = 'scan',
  PROJECT = 'projects',
}

// layer name in geoserver, must match geoserver settings
export enum SourceLayerId {
  PHOTO = 'photo',
  SCAN = 'scan',
}

export enum LayerId {
  SCAN = 'scan-asset-layer',
  SCAN_SELECTED = 'scan-asset-layer-selected',
  SCAN_UNASSIGNED = 'scan-asset-layer-unassigned',
  PHOTO = 'photo-asset-layer',
  PHOTO_SELECTED = 'photo-asset-layer-selected',
  PHOTO_UNASSIGNED = 'photo-asset-layer-unassigned',
  PROJECT_LINE = 'project-line-layer',
  PROJECT_FILL = 'project-fill-layer',
}

export const assetLayers = [
  LayerId.SCAN,
  LayerId.SCAN_SELECTED,
  LayerId.SCAN_UNASSIGNED,
  LayerId.PHOTO,
  LayerId.PHOTO_SELECTED,
  LayerId.PHOTO_UNASSIGNED,
];

export const interactiveLayers = Object.values(LayerId);

import { NavigationControl, ScaleControl } from 'react-map-gl';
import { Outlet } from 'react-router-dom';

import { FilterBox } from '@components/FilterBox';
import { FilterProvider } from '@components/FilterBox/useFilters';
import { InfoBox } from '@components/InfoBox/InfoBox';
import { MapContainer } from '@components/Map/MapContainer';
import { PanelContainer } from '@components/PanelContainer';

export const Page = () => {
  return (
    <FilterProvider>
      <MapContainer>
        <PanelContainer>
          <FilterBox />
        </PanelContainer>
        <InfoBox />
        <ScaleControl position="bottom-right" />
        <NavigationControl position="bottom-right" showCompass={false} />
        <Outlet />
      </MapContainer>
    </FilterProvider>
  );
};

import { useContext } from 'react';
import { Layer, type FillLayer, type LineLayer } from 'react-map-gl';

import { useFilters } from '@components/FilterBox/useFilters';
import { filterProjectAllExpression, projectCloseupThreshold } from '@utils/mapBoxExpressions';

import { ProjectColorsContext } from './MapSource';
import { LayerId, SourceId } from './constants';

export const ProjectsFillLayer = () => {
  const colors = useContext(ProjectColorsContext);
  const filters = useFilters();
  const projectsFilter = filterProjectAllExpression(filters);

  const layerStyle: FillLayer = {
    id: LayerId.PROJECT_FILL,
    paint: {
      'fill-color': ['match', ['get', 'id'], ...colors],
      'fill-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0.33, 9, 0.2, 12, 0.1],
    },
    type: 'fill',
    maxzoom: projectCloseupThreshold,
    source: SourceId.PROJECT,
    filter: projectsFilter,
  };

  return <Layer {...layerStyle} />;
};

export const ProjectsLineLayer = () => {
  const colors = useContext(ProjectColorsContext);
  const filters = useFilters();
  const projectsFilter = filterProjectAllExpression(filters);

  const layerStyle: LineLayer = {
    id: LayerId.PROJECT_LINE,
    paint: {
      'line-color': ['match', ['get', 'id'], ...colors],
      'line-width': ['interpolate', ['linear'], ['zoom'], 6, 0, 9, 4],
      'line-opacity': 1,
    },
    type: 'line',
    source: SourceId.PROJECT,
    filter: projectsFilter,
  };

  return <Layer {...layerStyle} />;
};

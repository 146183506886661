import { DateTime } from 'luxon';
import { useContext, createContext, useState, type ReactNode } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

type FiltersState = {
  from: DateTime | null;
  to: DateTime | null;
  scanDevices: string[];
  customers: string[];
  showAll: boolean;
};

export type Filters = FiltersState & {
  setFilters: (filters: Partial<Filters>) => void;
};

const INITIAL_STATE: Filters = {
  from: null,
  to: null,
  scanDevices: [],
  customers: [],
  showAll: false,
  setFilters: () => {},
};

// Sync FilterBox controlled components and url query params
const FilterContext = createContext<Filters>(INITIAL_STATE);

export const useFilters = () => useContext(FilterContext);

const mapSearchParamsToState = (searchParams: URLSearchParams): FiltersState => {
  const { from, to, scanDevices, showAll, customers } = INITIAL_STATE;

  const searchFrom = DateTime.fromISO(String(searchParams.get('from')));
  const searchTo = DateTime.fromISO(String(searchParams.get('to')));

  return {
    from: searchFrom.isValid ? searchFrom : from,
    to: searchTo.isValid ? searchTo : to,
    scanDevices: searchParams.get('scanDevices')?.split(',') || scanDevices,
    customers: searchParams.get('customers')?.split(',') || customers,
    showAll: searchParams.get('showAll') === 'true' || showAll,
  };
};

const mapStateToSearchParams = (filters: Partial<Filters>): string => {
  const { from, to, scanDevices, showAll, customers } = filters;

  return new URLSearchParams({
    ...(showAll && { showAll: 'true' }),
    ...(from && { from: from.toFormat('yyyy-LL-dd') }),
    ...(to && { to: to.toFormat('yyyy-LL-dd') }),
    ...(scanDevices?.length && { scanDevices: scanDevices.join(',') }),
    ...(customers?.length && { customers: customers.join(',') }),
  }).toString();
};

export const FilterProvider = ({ children }: { children: ReactNode }): ReactNode => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [filtersState, setFiltersState] = useState<FiltersState>(
    mapSearchParamsToState(searchParams),
  );

  const setFilters = (filters: Partial<Filters>) => {
    const searchParamsNext = {
      ...filtersState,
      ...filters,
    };

    const { pathname, hash } = window.location;

    navigate({
      search: mapStateToSearchParams(searchParamsNext),
      pathname,
      hash,
    });
    setFiltersState(searchParamsNext);
  };

  return (
    <FilterContext.Provider
      value={{
        ...filtersState,
        setFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

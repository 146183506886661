import { Route, Routes, Navigate } from 'react-router-dom';

import { Error as ErrorRoot } from '@pages/error';
import { Layout as LayoutRoot } from '@pages/layout';
import { Page as PageMap } from '@pages/map/page';

export const AppRoutes = () => (
  <Routes>
    <Route element={<LayoutRoot />} path="/">
      <Route element={<Navigate replace to="/map" />} index />
      <Route element={<PageMap />} path="map" />
      <Route element={<ErrorRoot />} path="*" />
    </Route>
  </Routes>
);

import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

import { useGeoJsonFeatures } from '@hooks/useGeoJsonFeatures';
import type { Asset } from '@models/asset';

type AssetPanelProps = {
  assets: Asset[] | null;
};

const MIXED_VALUE = 'Mixed';

const AssetProperty = ({ title, value }: { title: string; value: string | null }) => {
  if (!value) return null;

  return (
    <>
      <Grid xs={3}>{title}</Grid>
      <Grid sx={{ userSelect: 'all' }} textAlign={'right'} xs={9}>
        <span data-test-id={`asset-${title}`}>{value}</span>
      </Grid>
    </>
  );
};

const propValuesAreEqual = (assets: Asset[], prop: keyof Asset) =>
  assets.every((asset) => asset[prop] === assets[0][prop]);

export const AssetPanel = ({ assets }: AssetPanelProps) => {
  const { getProjectNameById } = useGeoJsonFeatures();

  if (!assets || assets.length === 0) return null;

  const mixedValue = assets.length > 1 ? MIXED_VALUE : null;

  const assetType = propValuesAreEqual(assets, 'asset_type')
    ? assets[0].asset_type.toUpperCase()
    : MIXED_VALUE;

  const assetOrg =
    [
      ...new Set(
        assets.map((asset) => asset.scan_organization_name || asset.photo_organization_name),
      ),
    ].length > 1
      ? MIXED_VALUE
      : assets[0].scan_organization_name || assets[0].photo_organization_name;

  const rawAssetDate = assets[0].asset_type === 'photo' ? assets[0].date : assets[0].end_date;
  const dt = new Date(rawAssetDate);
  const isoAssetDate = dt.toISOString();
  const assetDate =
    mixedValue ||
    DateTime.fromISO(isoAssetDate).toLocaleString({
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });

  const assetId = mixedValue || assets[0].id;

  const projectName = propValuesAreEqual(assets, 'project_id')
    ? getProjectNameById(assets[0].project_id)
    : MIXED_VALUE;

  const assetAddress = propValuesAreEqual(assets, 'address') ? assets[0].address : MIXED_VALUE;

  const assetDeviceName = propValuesAreEqual(assets, 'scan_device_name')
    ? assets[0].scan_device_name
    : MIXED_VALUE;

  return (
    <div data-test-id="asset-panel">
      <Typography component="div" gutterBottom variant="h6">
        Asset details {assets.length > 1 && `(${assets.length} selected)`}
      </Typography>
      <Typography component="div" gutterBottom variant="body2">
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Grid container spacing={1.5}>
            <AssetProperty title="Type" value={assetType} />
            <AssetProperty title="Device name" value={assetDeviceName} />
            <AssetProperty title="Organization" value={assetOrg} />
            <AssetProperty title="Address" value={assetAddress} />
            <AssetProperty title="Recorded At" value={assetDate} />
            <AssetProperty title="ID" value={assetId} />
            <AssetProperty title="Project" value={projectName} />
          </Grid>
        </LocalizationProvider>
      </Typography>
    </div>
  );
};

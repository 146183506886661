import { XOutline } from '@deepup/icons';
import { Alert, Divider, IconButton, SwipeableDrawer } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import { AssetPanel } from '@components/AssetPanel';
import { SourceId, SourceLayerId } from '@components/MapSource/constants';
import { ProjectAssignment } from '@components/PreviewAssignment';
import { useMapSourceReloader } from '@hooks/useMapSourceReloader';
import {
  type AssignmentParams,
  useProjectAssignment,
  type BatchAssignmentPayload,
  buildAssignmentPayload,
} from '@hooks/useProjectAssignment';
import { useThemeMode } from '@hooks/useThemeMode';
import type { Asset } from '@models/asset';

export const Sidebar = ({
  selectedFeatures,
  onClose,
}: {
  selectedFeatures: Asset[];
  onClose: (assets: Asset[]) => void;
}) => {
  const { isDarkMode, theme } = useThemeMode();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [currentAssetSelection, setCurrentAssetSelection] = useState<Asset[] | null>(
    selectedFeatures || null,
  );
  const { isPending, mutateAsync, isSuccess } = useProjectAssignment();
  const { reloadSource } = useMapSourceReloader();

  const assignToProject = async (projectId: string, projectName: string) => {
    const payload: AssignmentParams | BatchAssignmentPayload = buildAssignmentPayload(
      projectId,
      selectedFeatures,
    );

    await mutateAsync(payload, {
      onSuccess: () => {
        enqueueSnackbar({ message: `Asset assigned to "${projectName}"`, variant: 'success' });
      },
      onError: (error) => {
        enqueueSnackbar({ message: error.message, variant: 'error' });
      },
    });

    const reloadedSources: SourceId[] = [];

    for (const asset of selectedFeatures) {
      if (reloadedSources.length === 2) break;

      if (asset.asset_type === SourceLayerId.SCAN && !reloadedSources.includes(SourceId.SCAN)) {
        reloadSource(SourceId.SCAN);
        reloadedSources.push(SourceId.SCAN);
      }
      if (asset.asset_type === SourceLayerId.PHOTO && !reloadedSources.includes(SourceId.PHOTO)) {
        reloadSource(SourceId.PHOTO);
        reloadedSources.push(SourceId.PHOTO);
      }
    }

    setCurrentAssetSelection((prev) =>
      prev ? prev.map((asset) => ({ ...asset, project_id: projectId })) : null,
    );
  };

  const closeSidebar = () => {
    setOpen(false);
    // clear selected features after closing animation is done
    setTimeout(() => onClose([]), 300);
  };

  useEffect(() => {
    setOpen(!!selectedFeatures.length);

    if (selectedFeatures) {
      setCurrentAssetSelection(selectedFeatures);
    }
  }, [selectedFeatures]);

  return (
    <SwipeableDrawer
      ModalProps={{
        disableEnforceFocus: true,
        sx: {
          right: '0',
          bottom: '0',
          left: 'unset',
          padding: (theme) => theme.spacing(2),
          width: '395px',
        },
      }}
      PaperProps={{
        sx: {
          top: (theme) => theme.spacing(10),
          width: '395px',
          height: (theme) => `calc(100vh - ${theme.spacing(10)})`,
        },
      }}
      anchor="right"
      elevation={3}
      hideBackdrop
      onClose={closeSidebar}
      onOpen={() => {}}
      open={open}
      sx={{ width: '30%' }}
    >
      <Grid container direction={'column'} gap={2} height="100%" justifyContent={'space-between'}>
        <Grid borderBottom={1} borderColor={theme.palette.divider} padding={2}>
          <IconButton onClick={closeSidebar} size="small" sx={{ fontSize: '22px' }}>
            <XOutline fill={isDarkMode ? theme.palette.secondary.main : undefined} />
          </IconButton>
        </Grid>
        <Grid paddingX={2}>
          <AssetPanel assets={currentAssetSelection} />
        </Grid>
        <Grid paddingX={2}>
          <Divider />
        </Grid>
        <Grid paddingX={2}>
          <ProjectAssignment
            hasProject={
              currentAssetSelection?.length === 1 ? !!currentAssetSelection?.[0]?.project_id : true
            }
            isOpen={open}
            isSuccess={isSuccess}
            loading={isPending}
            onAssign={assignToProject}
          />
        </Grid>
        <Grid flexGrow={1} />
        <Grid padding={2}>
          <Alert severity="info">
            To select multiple assets, press and hold Shift, then click on each asset.
          </Alert>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
};

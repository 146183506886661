import { Box, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import type { ReactNode } from 'react';

type PanelContainerProps = {
  children: ReactNode;
};

const Panel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  left: theme.spacing(2),
  width: '390px',
}));

export const PanelContainer = ({ children }: PanelContainerProps) => {
  return (
    <Panel>
      <Grid container direction={'column'} height={'100%'} rowGap={2} wrap="nowrap">
        {children}
      </Grid>
    </Panel>
  );
};

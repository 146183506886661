import { useQuery } from '@tanstack/react-query';
import type { FeatureCollection, Feature } from 'geojson';

export const useGeoJsonFeatures = () => {
  const { data: projectsData } = useQuery<FeatureCollection>({
    queryKey: ['/geoserver/geojson?typeName=PROJECT'],
  });

  const projects = projectsData?.features || [];

  const projectsSource: FeatureCollection = {
    type: 'FeatureCollection',
    features: projects,
  };

  const getProjectNameById = (id: string | null) => {
    if (!id) return 'Unassigned';

    const project = projects.find((project: Feature) => {
      return project.properties?.id === id;
    });

    return project?.properties?.name || 'Unassigned';
  };

  return {
    projectsSource,
    getProjectNameById,
  };
};

import { Box, Paper } from '@mui/material';

import { useMapZoom } from '@hooks/useMapZoom';

export const InfoBox = () => {
  const zoom = useMapZoom();

  return (
    <Paper
      sx={{
        position: 'absolute',
        padding: '10px',
        left: '10px',
        bottom: '30px',
        borderRadius: '8px',
      }}
    >
      <Box component="div">Zoom: {zoom?.toFixed(2)}</Box>
    </Paper>
  );
};

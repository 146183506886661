import { AuthenticationError, Auth0Client } from '@auth0/auth0-spa-js';
import type { QueryFunctionContext } from '@tanstack/react-query';

import { getEnvironment } from '@utils/getEnvironment';

const { isIntegrationTest, auth0ClientId, auth0Domain, auth0Audience, apiBaseUrl } =
  getEnvironment();

export const getBearerToken = async () => {
  let token;

  if (isIntegrationTest) {
    return 'dummy-token';
  }

  const auth0 = new Auth0Client({
    domain: auth0Domain,
    clientId: auth0ClientId,
    authorizationParams: {
      audience: auth0Audience,
    },
  });

  try {
    token = await auth0.getTokenSilently();
  } catch (error) {
    const authenticationError = error as AuthenticationError;

    if (authenticationError.error !== 'login_required') {
      throw error;
    }
    auth0.loginWithRedirect();
  }

  return token;
};

export const defaultQueryFn = async ({ queryKey }: QueryFunctionContext) =>
  apiClient(queryKey[0] as string);

export const apiClient = async (url: string, init?: RequestInit | undefined) => {
  const token = await getBearerToken();

  const fetchUrl = `${apiBaseUrl}${url}`;
  const fetchInit = init || {};
  const response = await fetch(fetchUrl, {
    ...fetchInit,
    headers: {
      Authorization: `Bearer ${token}`,
      ...fetchInit.headers,
    },
  });

  if (response.ok) {
    return response.status !== 204 ? response.json() : null;
  }

  throw new Error(`Fetch Error ${response.status}`);
};

import { useMutation } from '@tanstack/react-query';

import type { Asset } from '@models/asset';
import { apiClient } from '@utils/apiClient';

export interface AssignmentParams {
  [key: string]: string;
  projectId: string;
  assetType: string;
  assetId: string;
}

export interface BatchAssignmentPayload {
  projectId: string;
  scanIds: string[];
  photoIds: string[];
}

export const buildAssignmentPayload = (projectId: string, selectedFeatures: Asset[]) => {
  return selectedFeatures.length === 1
    ? {
        projectId,
        assetId: selectedFeatures[0].id,
        assetType: selectedFeatures[0].asset_type.toUpperCase(),
      }
    : selectedFeatures.reduce<BatchAssignmentPayload>(
        (acc, asset) => {
          if (asset.asset_type === 'scan') {
            acc.scanIds.push(asset.id);
          } else if (asset.asset_type === 'photo') {
            acc.photoIds.push(asset.id);
          }

          return acc;
        },
        { projectId, scanIds: [], photoIds: [] } as BatchAssignmentPayload,
      );
};

const projectUpdater = async (params: AssignmentParams | BatchAssignmentPayload) => {
  if ('scanIds' in params) {
    return await apiClient(`/bulk-project-asset-linker`, {
      method: 'PUT',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } else {
    const queryString = new URLSearchParams(params).toString();

    return await apiClient(`/project-asset-linker?${queryString}`, {
      method: 'PUT',
    });
  }
};

export const useProjectAssignment = () =>
  useMutation({
    mutationFn: projectUpdater,
  });

import { Autocomplete, Stack, TextField, debounce } from '@mui/material';
import { LngLat } from 'mapbox-gl';
import { useState, type SyntheticEvent } from 'react';

import { useMapFocus } from '@hooks/useMapFocus';
import { getEnvironment } from '@utils/getEnvironment';

type MapboxGeocoderResult = {
  text: string;
  place_name: string;
  center: [number, number];
};

export const GeocoderField = () => {
  const [searchResults, setSearchResults] = useState<MapboxGeocoderResult[]>([]);
  const { focusLocation } = useMapFocus();

  const fetchCall = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;

    if (searchTerm === '') {
      setSearchResults([]);

      return;
    }
    const encodedSearchTerm = encodeURIComponent(searchTerm);

    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedSearchTerm}.json?language=de&access_token=${getEnvironment().mapboxAccessToken}`,
    );

    if (!response.ok) {
      return;
    }

    const data = (await response.json()) as { features: MapboxGeocoderResult[] };

    setSearchResults(data.features);
  };

  const onSearchResultClick = (
    _: SyntheticEvent<Element, Event>,
    value: MapboxGeocoderResult | string | null,
  ) => {
    if (!value || typeof value !== 'object') return;

    const {
      center: [lng, lat],
    } = value;

    if (!lat || !lng) return;

    focusLocation({ center: new LngLat(lng, lat), zoom: 14, duration: 2000 });

    setSearchResults([]);
  };

  const autocompleteOptions = searchResults.map((result) => ({
    text: result.text,
    place_name: result.place_name.replace(result.text + ', ', ''),
    center: result.center,
  }));

  return (
    <Autocomplete
      freeSolo
      getOptionLabel={(option: MapboxGeocoderResult | string) =>
        typeof option === 'string' ? option : `${option.text}, ${option.place_name}`
      }
      id="mapbox-search-input"
      onChange={onSearchResultClick}
      onInput={debounce(fetchCall, 250)}
      options={autocompleteOptions}
      renderInput={(params) => <TextField {...params} label="Search" />}
      renderOption={(props, option) => (
        <li {...props}>
          <Stack>
            <strong>{option.text}</strong>
            <span>{option.place_name}</span>
          </Stack>
        </li>
      )}
    />
  );
};

import { useAuth0, Auth0Provider } from '@auth0/auth0-react';
import type { ReactNode } from 'react';

import { getEnvironment } from '@utils/getEnvironment';

export type AuthProps = {
  children: ReactNode;
};

const AuthContainer = ({ children }: AuthProps) => {
  const { isAuthenticated, isLoading, error, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated && error) {
    return <h3>Error: {error?.message}</h3>;
  }

  if (!isAuthenticated) {
    loginWithRedirect({ appState: { returnTo: window.location.href } }).catch((e) =>
      console.error(e),
    );

    return null;
  }

  return children;
};

export const Auth = (props: AuthProps) => {
  const { children } = props;
  const { auth0ClientId, auth0Domain, auth0Audience, isIntegrationTest } = getEnvironment();

  if (isIntegrationTest) {
    return children;
  }

  return (
    <Auth0Provider
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: auth0Audience,
      }}
      clientId={auth0ClientId ?? ''}
      domain={auth0Domain ?? ''}
    >
      <AuthContainer>{children}</AuthContainer>
    </Auth0Provider>
  );
};

import type { VectorSourceImpl } from 'mapbox-gl';
import { useMap } from 'react-map-gl';

import type { SourceId } from '@components/MapSource/constants';
import { getEnvironment } from '@utils/getEnvironment';

const { isIntegrationTest } = getEnvironment();

export const useMapSourceReloader = () => {
  const { current: map } = useMap();
  const reloadSource = (sourceId: SourceId) => {
    if (!map || isIntegrationTest) {
      // Integration tests dont use vector tiles, so we skip reloading of the vector source
      return;
    }

    const src = map.getSource(sourceId) as VectorSourceImpl;

    src.reload();
  };

  return { reloadSource };
};

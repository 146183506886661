import { type ReactNode } from 'react';
import { type MapLayerMouseEvent } from 'react-map-gl';

import { Map } from '@components/Map';
import { useMapState } from '@components/Map/useMapState';
import { MapSource } from '@components/MapSource';
import { Popup } from '@components/Popup';
import { Sidebar } from '@components/Sidebar';
import './Map.css';
import { getEnvironment } from '@utils/getEnvironment';

export const MapContainer = ({ children }: { children: ReactNode }) => {
  const {
    selectPointFeature,
    showProjectNamePopup,
    setPopupCoordinates,
    popupCoordinates,
    projectName,
    selectedFeatures,
    setSelectedFeatures,
  } = useMapState();
  const { isIntegrationTest } = getEnvironment();

  const onClickHandler = (e: MapLayerMouseEvent) => {
    setPopupCoordinates(null);
    selectPointFeature(e);
    if (isIntegrationTest) {
      // print coordinates to for generating test data
      // eslint-disable-next-line no-console
      console.log('coordinates', [e.lngLat.lng, e.lngLat.lat]);
    }
  };

  const onMouseEnterHandler = (e: MapLayerMouseEvent) => {
    showProjectNamePopup(e);
    e.target.getCanvas().style.cursor = 'pointer';
  };

  const onMouseLeaveHandler = (e: MapLayerMouseEvent) => {
    e.target.getCanvas().style.cursor = '';
  };

  const onZoomHandler = () => {
    setPopupCoordinates(null);
  };

  return (
    <Map
      onClick={onClickHandler}
      onMouseLeave={onMouseLeaveHandler}
      onMouseMove={onMouseEnterHandler}
      onZoom={onZoomHandler}
    >
      {!isIntegrationTest && <MapSource selectedFeatures={selectedFeatures || []} />}
      <Popup coords={popupCoordinates}>{projectName}</Popup>
      <Sidebar onClose={setSelectedFeatures} selectedFeatures={selectedFeatures} />
      {children}
    </Map>
  );
};

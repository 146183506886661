import type { BBox } from 'geojson';
import { LngLatBounds, type FlyToOptions } from 'mapbox-gl';
import { useCallback, useMemo } from 'react';
import { useMap } from 'react-map-gl';

import { bboxToBounds } from '@utils/bboxToBounds';

export const useMapFocus = (mapId?: string) => {
  const maps = useMap();

  const map = useMemo(() => (mapId ? maps[mapId] : maps.current), [maps, mapId]);

  const focusLocation = useCallback(
    ({ duration = 1000, zoom, minZoom, ...rest }: FlyToOptions) => {
      if (!map) return;
      const realZoom = zoom ?? map.getZoom();

      map.flyTo({ ...rest, zoom: minZoom ? Math.max(minZoom, realZoom) : realZoom, duration });
    },
    [map],
  );

  const focusBounds = useCallback(
    (bounds: LngLatBounds | BBox, padding?: number) => {
      if (bounds instanceof LngLatBounds) {
        map?.fitBounds(bounds, { padding });
      } else {
        map?.fitBounds(bboxToBounds(bounds), { padding });
      }
    },
    [map],
  );

  return {
    focusLocation,
    focusBounds,
  };
};

import type { CirclePaint } from 'mapbox-gl';
import type { MapStyle } from 'react-map-gl';

import { LayerId, SourceId } from '@components/MapSource/constants';

export const pointPaintStyle: CirclePaint = {
  'circle-radius': [
    'interpolate',
    ['linear'],
    ['zoom'],
    6,
    10,
    // zoom is 9 (or less) -> circle radius will be 5px
    9,
    20,
    // zoom is 10 (or greater) -> circle radius will be 6px
    10,
    30,
  ],
  'circle-pitch-scale': 'map',
};

const FIXTURE_ENDPOINT_SCAN = '/geoserver/geojson?typeName=SCAN';
const FIXTURE_ENDPOINT_PHOTO = '/geoserver/geojson?typeName=PHOTO';
const FIXTURE_ENDPOINT_PROJECT = '/geoserver/geojson?typeName=PROJECT';

// https://docs.mapbox.com/mapbox-gl-js/guides/browsers-and-testing/#automated-tests
export const testMapStyle: MapStyle = {
  version: 8,
  sources: {
    [SourceId.SCAN]: {
      type: 'geojson',
      data: FIXTURE_ENDPOINT_SCAN,
    },
    [SourceId.PHOTO]: {
      type: 'geojson',
      data: FIXTURE_ENDPOINT_PHOTO,
    },
    [SourceId.PROJECT]: {
      type: 'geojson',
      data: FIXTURE_ENDPOINT_PROJECT,
    },
  },
  layers: [
    {
      id: LayerId.PROJECT_FILL,
      type: 'fill',
      paint: {
        'fill-color': 'green',
        'fill-opacity': 0.5,
      },
      source: SourceId.PROJECT,
    },
    {
      id: LayerId.SCAN,
      type: 'circle',
      paint: {
        ...pointPaintStyle,
        'circle-color': 'red',
      },
      source: SourceId.SCAN,
    },
    {
      id: LayerId.PHOTO,
      type: 'circle',
      paint: {
        ...pointPaintStyle,
        'circle-color': 'red',
      },
      source: SourceId.PHOTO,
    },
  ],
};

import { useState } from 'react';
import { useMap } from 'react-map-gl';

import { useMapEvent } from '@hooks/useMapEvent';

// max possible zoom in mapbox
const MAPBOX_ZOOM_MAX = 1;

export const useMapZoom = () => {
  const { current: map } = useMap();
  const [zoom, setZoom] = useState<number>(MAPBOX_ZOOM_MAX);

  const moveListener = () => {
    if (!map) return;
    setZoom(map.getZoom());
  };

  useMapEvent('move', moveListener);
  useMapEvent('load', moveListener);

  return zoom;
};

import { type ReactNode, useEffect, useState } from 'react';
import { Map as MapGl, type MapboxEvent, type MapLayerMouseEvent } from 'react-map-gl';

import { interactiveLayers } from '@components/MapSource/constants';
import { useThemeMode } from '@hooks/useThemeMode';
import { getBearerToken } from '@utils/apiClient';
import { getEnvironment } from '@utils/getEnvironment';

import 'mapbox-gl/dist/mapbox-gl.css';
import { testMapStyle } from './testMapStyle';

const { mapboxAccessToken, isIntegrationTest } = getEnvironment();

const INITIAL_VIEW_STATE = {
  zoom: 5.42,
  latitude: 51.1427959254,
  longitude: 7.8,
};

export type MapProps = {
  children: ReactNode;
  onClick?: (e: MapLayerMouseEvent) => void;
  onMouseDown?: (e: MapLayerMouseEvent) => void;
  onMouseMove?: (e: MapLayerMouseEvent) => void;
  onMouseUp?: (e: MapLayerMouseEvent) => void;
  onMouseLeave?: (e: MapLayerMouseEvent) => void;
  onMouseEnter?: (e: MapLayerMouseEvent) => void;
  onIdle?: () => void;
  onZoom?: () => void;
  onLoad?: (e: MapboxEvent) => void;
};

export const Map = ({
  children,
  onClick = () => {},
  onMouseMove = () => {},
  onMouseDown = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  onMouseUp = () => {},
  onIdle = () => {},
  onZoom = () => {},
  onLoad = () => {},
}: MapProps) => {
  const [bearerToken, setBearerToken] = useState('');
  const { isDarkMode } = useThemeMode();

  useEffect(() => {
    const getToken = async () => {
      const token = await getBearerToken();

      if (token) {
        setBearerToken(token);
      }
    };

    getToken();
  }, []);

  if (!bearerToken) {
    return null;
  }

  const mapStyle = isIntegrationTest
    ? testMapStyle
    : isDarkMode
      ? 'mapbox://styles/deepup/clto3o7gc003x01qw33z06s6l'
      : 'mapbox://styles/deepup/clto5zh19000101qv403t638q';

  return (
    <MapGl
      boxZoom={false}
      dragRotate={false}
      hash
      initialViewState={INITIAL_VIEW_STATE}
      interactiveLayerIds={interactiveLayers}
      mapStyle={mapStyle}
      mapboxAccessToken={mapboxAccessToken}
      onClick={onClick}
      onIdle={onIdle}
      onLoad={onLoad}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onZoom={onZoom}
      projection={{ name: 'mercator' }}
      style={{ width: '100%', flex: '1' }}
      testMode={isIntegrationTest}
      touchZoomRotate={false}
      transformRequest={(url) => {
        return {
          url,
          headers: url.includes('api.mapbox')
            ? undefined
            : {
                Authorization: `Bearer ${bearerToken}`,
              },
        };
      }}
    >
      {children}
    </MapGl>
  );
};

import * as i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import enJsonUrl from './assets/locales/en/translation.json?url';

const languageDetector = new LanguageDetector();

const supportedLanguages = {
  en: enJsonUrl,
};

export type SupportedLanguage = keyof typeof supportedLanguages;
const fallbackLanguage: SupportedLanguage = 'en';

i18next
  .use(Backend)
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    detection: {
      ...languageDetector.i18nOptions,
      convertDetectedLanguage: (language) => {
        if (language in supportedLanguages) {
          return language;
        }

        return fallbackLanguage;
      },
    },
    backend: {
      loadPath: (languages: SupportedLanguage[]) => supportedLanguages[languages[0]],
    },
    load: 'languageOnly',
    fallbackLng: fallbackLanguage,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
